
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store,
} from "@/components/CyanPageWrapper.vue";
import { territorios } from "@/modules/cyanRegions";
import { IonButton, IonIcon } from "@ionic/vue";
import cyanRequest from "@/modules/cyanRequest";
import router from "@/router";
import { personAdd, personRemove } from "ionicons/icons";

export default defineComponent({
  name: "DirectorioMember",
  components: {
    CyanPageWrapper,
    IonPage,
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      personAdd,
      personRemove,
    };
  },
  computed: {
    seed(): any {
      return (this as any).$route.params.ambito || "SV";
    },
    idMember(): any {
      return (this as any).$route.params.id || 0;
    },
    memberData(): any {
      const s = this.datos;

      if (!s.datosCargos || !s.datosCargos[(this as any).$route.params.id])
        return {};

      return s.datosCargos[(this as any).$route.params.id];
    },

    datos(): any {
      const s = this.esJunta
        ? store.state.dirJunta[(this as any).$route.params.junta]
        : store.state.dirCentro[(this as any).$route.params.centro];

      if (!s || !s.ok) return {};

      return s;
    },

    datosCentro() {
      return (this as any).datos.centro || {};
    },

    numeroJunta() {
      return (this as any).datos.junta || false;
    },

    centroJunta() {
      const j = (this as any).numeroJunta;

      let cj = j ? "JRV " + j + ", " : "";

      if (this.datosCentro.nombre) cj += " " + this.datosCentro.nombre;

      return cj;
    },

    backRoute() {
      const pp = (this as any).$route.path.split("/");
      pp.pop();
      return pp.join("/");
    },
    territorio() {
      if (!this.datos.centro || !this.datos.centro.nivel) return "";
      const n = this.datos.centro.nivel as any;
      const t = (territorios as any)[n];
      return t.nombrePadre && t.nombrePadre != t.nombre
        ? t.nombre + ", " + t.nombrePadre
        : t.nombre;
    },
    telefonoGuay() {
      const t = this.memberData.telefono as string;
      if (!t) return "";
      if (!t.match(/^[0-9]{8}$/)) return t;
      return t.substr(0, 4) + "-" + t.substr(4);
    },
    esJunta() {
      const head = (this as any).$route.path.split("/")[1];
      return head === "dirJunta" || head === "dirJuntaA";
    },
    telefonoUrl() {
      const t = this.memberData.telefono as string;
      if (!t || t == "") return "";
      let url = "tel:";
      if (t.match("/^[0-9]{8}$/")) {
        url += t;
      } else {
        // partir en todo lo que no sean números, espacios, guiones, () y el + inicial

        const tieneMas = t[0] == "+";

        const tTrabajo = tieneMas ? t.substring(1) : t;

        const piezas = tTrabajo
          .replace(/[^-() 0-9]/g, "|")
          .split("|") as string[];

        if (tieneMas) url += "+";
        url += piezas[0].replace(/[^0-9]/g, "");
      }

      return url;
    },
  },
  methods: {
    marcarAsistencia(desmarcar: boolean) {
      const br = this.backRoute;
      cyanRequest(
        (desmarcar ? "unsetAsistencia/" : "setAsistencia/") +
          this.memberData.id,

        {
          needsAuth: true,
          isModal: true,
          isPost: true,
        }
      ).then(function (d) {
        if (d.ok || d.unchanged) {
          // STORE

          store.commit("storeAsistencia", d);

          router.replace(br);
        }
      });
    },
  },
});
